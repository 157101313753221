const defaultBranding = {
    // type of product - eg, "forms"
    ProductType: "music",
    // menu text to select product search tab - eg, "Forms Search"
    SearchLabel: "My Music App Search",
    // meta keyword specified in manifest (might not be the same as product type) - eg, "forms"
    MetaSearchKeyword: "music",
    // example searches on first run - eg, "Taxes, Immigration, US"
    SearchExample: "Pop, Rock, Jazz",
    // eg, "Search form titles or tags"
    AutocompletePlaceholder: "Search for music",
    // eg, "Search our database for forms by typing in a form query in the search box above."
    IntroLI1: "Search and listen to the best live radio stations ",
    // eg, "You can also browse our forms database by 'tag' located on the left side menu of the page."
    IntroLI2: "test IntroLI2",
    FirstRunSettings: false,
};

const windowBranding = window.branding || {};

const PRODUCT_SEARCH_PROVIDERS = {
    default: {
        name: windowBranding.SearchEngineName,
        id: windowBranding.SearchId,
        isDefault: true,
    },
    iheartradio: {
        name: "iHeartRadio",
        id: "iheartradio",
        isDefault: false,
        url: "https://www.iheart.com/search/?q={{QUERY}}",
    },
    radio: {
        name: "Radio.com",
        id: "radio",
        isDefault: false,
        url: "https://player.radio.com/search/{{QUERY}}",
    },
    lastfm: {
        name: "Last.fm",
        id: "lastfm",
        isDefault: false,
        url: "https://www.last.fm/search?q={{QUERY}}",
    },
};

const altSearchProvidersUrls = function(query) {
    const altProviders = Object.entries(PRODUCT_SEARCH_PROVIDERS).filter(
        ([key, value]) => !value.isDefault
    );
    return altProviders.map(([key, value]) => {
        return {
            name: value.name,
            url: value.url.replace("{{QUERY}}", query),
        };
    });
};

const DEFAULT_PRODUCT_SEARCH_PROVIDER = PRODUCT_SEARCH_PROVIDERS.default.id;

function getBranding() {
    return {
        ...defaultBranding,
        ...windowBranding,
    };
}

export {
    getBranding,
    PRODUCT_SEARCH_PROVIDERS,
    altSearchProvidersUrls,
    DEFAULT_PRODUCT_SEARCH_PROVIDER,
};
