<template>
    <div id="main-wrapper" class="mainWrapper">
        <div id="tr_audio_player" class="tr_audioPlayer" v-if="isSupported">
            <audio autoplay id="tr_audio_object" :src="audioSrc">
                This browser does not support HTML5
            </audio>
            <div id="tr_audioPlayer-data" class="tr_Data">
                <div class="tr-audio-tb-1" v-show="audioStationTitle">
                    <div id="tr_Genre">Station:</div>
                    <div id="tr_Title">Title:</div>
                    <div id="tr_Station">Genre:</div>
                </div>
                <div class="tr-audio-tb-2">
                    <div class="tr-control-station">
                        <div class="marquee slow">
                            <div>
                                <span>{{ audioStationTitle }}</span>
                                <span>{{ audioStationTitle }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="tr-control-title">
                        <div class="marquee fast">
                            <div>
                                <span>{{ currentSongTitle }}</span>
                                <span>{{ currentSongTitle }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="tr-control-genre">{{ audioStationGenre }}</div>
                </div>
            </div>
            <div id="tr_controls" class="tr_audioPlayer-controls">
                <button
                    id="tr_play_pause"
                    class="tr_control_button"
                    @click="pressPlayPause()"
                >
                    {{ playPauseButton }}
                </button>
                <input
                    id="tr_volume"
                    type="range"
                    v-model="volume"
                    ref="Volume"
                    min="0"
                    max="100"
                    @click="blurVolume()"
                />
            </div>
        </div>
        <div id="not_Supported" class="notSupported" v-else>
            <span> Your browser is not supported </span>
        </div>
    </div>
</template>

<script>
import {
    PLAY_ICON,
    PAUSE_ICON,
    SONG_INFO_INTERVAL,
    sleep,
    GET_INFO_INTERVAL,
} from "./lib/tr_audioplayer";
import { SONG_INFO_API_URL } from "../urls";

export default {
    name: "tr-audioplayer",
    props: [
        "audioSource",
        "stationTitle",
        "stationGenre",
        "stationID",
        "currentGUID",
    ],

    data() {
        return {
            playState: false,
            audio: null,
            audioSrc: null,
            playPauseButton: PLAY_ICON,
            volume: 50,
            prevVolume: 100,
            audioStationTitle: "",
            audioStationGenre: "",
            audioStationID: "",
            currentSongTitle: "",
            previousStationTitle: "",
            previousStationGenre: "",
            previousStationUrl: "",
            previousStationID: "",
            isMuted: false,
            isSupported: true,
            running: true,
            extensionChannel: "",
            guid: "",
        };
    },
    mounted() {
        this.setAudioPlayer();
        this.updateCurrentPlayingInfo();
        this.guid = this.currentGUID;
        window.postMessage({
            type: "radioState",
            player_task: "connect",
            channelName: this.currentGUID || "Default_Player",
        });
        if (this.guid)
            this.extensionChannel = new BroadcastChannel(this.currentGUID);
        else this.extensionChannel = new BroadcastChannel("Default_Player");
        window.addEventListener("keydown", e => {
            // checking to make sure not entering an input
            const activeElement = document.activeElement.tagName.toLowerCase();
            if (!(activeElement === "input" || activeElement === "select")) {
                if (e.keyCode == 32 && e.target == document.body) {
                    e.preventDefault();
                }
            }
        });
        window.addEventListener("keyup", e => {
            // checking to make sure not entering an input
            const activeElement = document.activeElement.tagName.toLowerCase();
            if (!(activeElement === "input" || activeElement === "select")) {
                if (e.keyCode == 32 && e.target == document.body) {
                    e.preventDefault();
                }
                switch (e.which) {
                    case 32:
                        // when spacebar is pressed
                        this.pressPlayPause();
                        e.preventDefault(); // stops browser from scrolling down on spacebar entry
                        break;
                    case 77:
                        // when m is pressed
                        this.setMute();
                        break;
                    case 80:
                        // when p is pressed
                        this.setPrevious();
                        break;
                    case 188:
                        // when comma/less than is pressed
                        this.decreaseVolume(5);
                        break;
                    case 190:
                        // when period/more than is pressed
                        this.increaseVolume(5);
                        break;
                }
            }
        });
    },
    created() {
        if (this.guid)
            this.extensionChannel = new BroadcastChannel(this.currentGUID);
        else this.extensionChannel = new BroadcastChannel("Default_Player");
    },
    beforeDestroy() {
        this.running = false;
        window.removeEventListener("keydown", () => {});
    },
    watch: {
        audioSource() {
            if (this.audio) {
                this.previousStationTitle = this.audioStationTitle;
                this.previousStationGenre = this.audioStationGenre;
                this.previousStationUrl = this.audioSrc;
                this.previousStationID = this.audioStationID;
                this.audioStationTitle = this.stationTitle;
                this.audioStationGenre = this.stationGenre;
                this.audioStationID = this.stationID;
                this.audioSrc = this.audioSource;
                if (this.audioSrc) this.setPlay();
                this.currentSongTitle = "";
                if (this.isMuted) this.volume = 50;
                if (this.running == false) this.updateCurrentPlayingInfo();
            } else {
                this.playPauseButton = PLAY_ICON;
            }
        },
        volume() {
            if (this.audio) {
                this.audio.volume = this.volume / 100;
            }
            if (this.audio.volume == 0) {
                this.isMuted = true;
            } else this.isMuted = false;
            this.$refs.Volume.blur();
        },
    },
    methods: {
        pressPlayPause() {
            if (this.audioSrc && this.audio) {
                if (!this.audio.paused) {
                    this.setPause();
                } else {
                    this.setPlay();
                }
            }
        },
        setMute(forceOn = false) {
            if (this.audio.volume == 0) {
                if (this.preVolume == 0 || forceOn) this.volume = 50;
                else this.volume = this.prevVolume;
                this.isMuted = false;
            } else {
                this.prevVolume = this.volume;
                this.volume = 0;
                this.isMuted = false;
            }
        },
        decreaseVolume(decrease) {
            if (this.volume > decrease) this.volume -= 5;
            else this.volume = 0;
        },
        increaseVolume(increase) {
            if (this.volume < 100 - increase) this.volume += 5;
            else this.volume = 100;
        },
        setAudioPlayer() {
            this.audio = document.getElementById("tr_audio_object");
        },
        setPrevious() {
            if (this.audio && this.previousStationUrl && this.audioSrc) {
                const station = {
                    pURL: this.previousStationUrl,
                    pTitle: this.previousStationTitle,
                    pGenre: this.previousStationGenre,
                    pID: this.previousStationID,
                };
                this.previousStationUrl = this.audioSrc;
                this.previousStationTitle = this.audioStationTitle;
                this.previousStationGenre = this.audioStationGenre;
                this.previousStationID = this.audioStationID;
                this.audioSrc = station.pURL;
                this.audioStationTitle = station.pTitle;
                this.audioStationGenre = station.pGenre;
                this.audioStationID = station.pID;
                this.$emit("interface", station.pID);
                if (this.isMuted) this.volume = 50;
                if (this.running == false) this.updateCurrentPlayingInfo();
            }
        },
        async updateCurrentPlayingInfo() {
            let errorCount = 0;
            this.running = true;
            while (this.running) {
                const promise = sleep(GET_INFO_INTERVAL);
                const promiseResult = await promise;
                if (this.audioSrc) {
                    const req = await fetch(
                        `${SONG_INFO_API_URL}?l=${this.audioSrc}`
                    );
                    if (req.ok) {
                        const metadata = await req.json();
                        this.currentSongTitle = this.decodeText(
                            metadata.SongTitle
                        );
                        errorCount = 0;
                    } else {
                        // cannot get info from proxy
                        if (errorCount < 5) {
                            this.currentSongTitle =
                                "Error: Cannot Get Metadata";
                            errorCount++;
                        } else {
                            this.running = false;
                            return;
                        }
                    }
                }
            }
        },
        blurVolume() {
            this.$refs.Volume.blur();
        },
        setPlay() {
            if (this.audio && this.audioSrc != "") {
                this.audio.play();
                this.playPauseButton = PAUSE_ICON;
                this.extensionChannel.postMessage({
                    type: "radioState",
                    task: "play",
                });
            }
        },
        setPause() {
            if (this.audio && this.audioSrc) {
                this.audio.pause();
                this.playPauseButton = PLAY_ICON;
                this.extensionChannel.postMessage({
                    type: "radioState",
                    task: "pause",
                });
            }
        },
        decodeText(text) {
            const txt = document.createElement("textarea");
            txt.innerHTML = text;
            return txt.value;
        },
    },
};
</script>

<style>
@import "./css/TR_AudioPlayer.css";
</style>
