const PLAY_ICON = getFromUnicode("&#9658");
const PAUSE_ICON = getFromUnicode("&#10074;&#10074;");
const GET_INFO_INTERVAL = 10000; // in milliseconds
const sleep = milliseconds => {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
};

function getFromUnicode(unicodeStr) {
    const parser = new DOMParser();
    const dom = parser.parseFromString(
        `<!doctype html><body>${unicodeStr}`,
        "text/html"
    );
    return dom.body.textContent;
}

export { PAUSE_ICON, PLAY_ICON, GET_INFO_INTERVAL, sleep };
