<template>
    <!--main wrapper-->
    <div class="serp-wrapper">
        <!-- Bookmark Popup  -->
        <first-run
            id="first_run"
            :which="whichPop"
            :onClose="onCloseHelp"
            :onNext="onNextPop"
            :pEngine="productEngine"
        />
        <!--header wrapper-->
        <div id="header" class="header-wrapper">
            <!--nav-->
            <div id="serp-nav">
                <div
                    id="job-btn"
                    class="serp-nav-item"
                    :class="{ active: productSearch }"
                    @click="toProductSearch()"
                >
                    {{ branding.SearchLabel }}
                </div>
                <div
                    id="web-btn"
                    class="serp-nav-item"
                    :class="{ active: !this.productSearch }"
                    @click="toWebSearch()"
                >
                    Web Search
                </div>
            </div>
            <!--End of nav-->
            <!--header-->
            <div class="serp-header" id="serp_header">
                <div id="this-search" class="thisSearch" v-show="productSearch">
                    <div id="search-pad-right" class="search pad-right">
                        <input
                            id="product-search-input"
                            v-model="query"
                            ref="queryInput"
                            :placeholder="branding.AutocompletePlaceholder"
                            @selected="onSugSelected()"
                            @return="onSugSelected()"
                            @keydown.enter="onSugSelected()"
                        />
                        <div id="s-icon-this-search" class="s-icon">
                            <i class="fa fa-search" aria-hidden="true"></i>
                        </div>
                        <div
                            id="product-input-error"
                            class="error"
                            v-if="queryError"
                        >
                            This field is required, please enter
                        </div>
                    </div>

                    <button
                        id="product-btn-search"
                        class="btn-search"
                        @click="onSugSelected()"
                    >
                        {{ branding.SearchLabel }}
                    </button>
                </div>
                <div id="web-search" class="webSearch" v-show="!productSearch">
                    <div id="search-web" class="search web">
                        <input
                            ref="webSearch"
                            id="web-search-input"
                            placeholder="Search the web"
                            v-model="wQuery"
                            @keydown.enter="startWebSearch()"
                        />
                        <div id="s-icon-web-search" class="s-icon">
                            <i class="fa fa-globe" aria-hidden="true"></i>
                        </div>
                        <div
                            class="error"
                            id="web-input-error"
                            v-if="queryError"
                        >
                            This field is required, please enter
                        </div>
                    </div>
                    <button
                        id="web-btn-search"
                        class="btn-search"
                        @click="startWebSearch()"
                    >
                        Web Search
                    </button>
                </div>
            </div>

            <!--End of Header-->
        </div>
        <!--End of header wrapper-->
        <!--serp results-->
        <div id="body-wrapper" class="bodyWrapper">
            <div id="serp-results" class="serp-results">
                <!--Left col-->
                <div id="serp-results-left" class="serp-results-left">
                    <div class="serp-results-left-inner">
                        <!-----End of First Run Add on------>
                        <div
                            id="serp-stats"
                            class="serp-stats"
                            v-show="hasSearchResults && !showTopStations"
                        >
                            Showing {{ numResults }} stations
                            <span v-show="searchTerm"
                                >for
                                <span class="kw">{{ searchTerm }}</span></span
                            ><span v-show="searchTermGenre.id > -1">
                                in the
                                <span class="filter">{{
                                    searchTermGenre.name
                                }}</span>
                                genre</span
                            >
                        </div>
                        <!--NoResults-->
                        <div
                            id="serp-no-results"
                            class="serp-noresults"
                            v-show="
                                !hasSearchResults &&
                                    searchTerm != '' &&
                                    searchCompleted
                            "
                        >
                            <h3>
                                The search
                                <span class="kw" v-if="searchTerm">{{
                                    searchTerm
                                }}</span
                                ><span v-if="searchTermGenre.id > -1">
                                    in the
                                    <span class="kw">{{
                                        searchTermGenre.name
                                    }}</span>
                                    genre</span
                                >
                                did not match any {{ branding.ProductType }}.
                                Try the suggestions below or type a new query
                                above.
                            </h3>
                            <ul>
                                <li>Check your spelling.</li>
                                <li>Try more general keywords</li>
                                <li>
                                    Replace abbreviations with the entire word
                                </li>
                            </ul>
                            <h2>Still no results!</h2>
                            <h3 class="bs">
                                We try our best to keep up our database up to
                                date, but unfortunately with hundreds of new
                                {{ branding.ProductType }} Stations created
                                every day, it is sometimes hard to account for
                                all new updates. Try the links below to some
                                other resources that may help with your search.
                            </h3>
                            <br />
                            <p v-for="{ name, url } in altSearchUrls">
                                <a
                                    class="other-res"
                                    :href="url"
                                    :title="name"
                                    target="_blank"
                                    ><p>{{ name }}</p></a
                                >
                            </p>
                        </div>
                        <!-- End of NoResults-->
                        <!--radio list-->
                        <div id="radio-list" v-if="showGenreTiles">
                            <div class="title-raido-list">
                                Radio Station Genres
                            </div>
                            <div
                                class="radioCrad"
                                v-for="genre in primaryGenres"
                                @click="selectGenreTile(genre)"
                                :id="`radioCrad_${genre.name}`"
                            >
                                <div
                                    class="radioCover"
                                    :id="`radioCover_${genre.name}`"
                                >
                                    <img
                                        :src="
                                            `/assets/images/radio/${getGenreImagePath(
                                                genre.name
                                            )}.jpg`
                                        "
                                    />
                                </div>
                                <div
                                    class="radioTitle"
                                    :id="`radioTitle_${genre.name}`"
                                >
                                    {{ decodeText(genre.name) }}
                                </div>
                            </div>
                        </div>
                        <!--End of radio Lists-->
                        <div id="has-search-results" v-show="hasSearchResults">
                            <div
                                class="title-raido-list"
                                v-show="showTopStations"
                            >
                                Top Stations
                            </div>
                            <!--results-->
                            <div
                                :id="`srl_result_${result.name}`"
                                class="srl has-image"
                                v-for="result in records"
                                @click="
                                    getStation(
                                        result.id,
                                        result.name,
                                        result.genre
                                    )
                                "
                            >
                                <div
                                    id="srl-info"
                                    class="srl-info"
                                    :class="{
                                        'srl-active':
                                            result.id == currentStationID,
                                    }"
                                >
                                    <h3 id="srl-title" class="srl-title">
                                        {{ getTitleText(result) }}
                                    </h3>
                                    <div
                                        id="srl-description"
                                        v-show="result.name"
                                        class="srl-description"
                                    >
                                        Station:
                                        {{ getStationText(result.name) }}
                                    </div>
                                </div>
                            </div>
                            <!--end of results-->
                        </div>
                        <!--Browse Panel-->
                        <!--End of left col-->
                    </div>
                </div>
                <!--Right col -->
                <div id="right-col" class="rightCol">
                    <div class="rightColInner">
                        <!--logo-->
                        <div id="serp-logo" class="serp-logo">
                            <img :src="branding.Logo" />
                        </div>
                        <div id="other-functions">
                            <div class="other-functions-list rightMenu">
                                <div
                                    id="showGenresBtn"
                                    class="menu-list"
                                    :class="{ active: showGenreTiles }"
                                    @click="toggleGenreTiles()"
                                >
                                    <i class="fa fa-music"></i>
                                    <span> Station Genres </span>
                                </div>
                                <div
                                    id="topStationsBtn"
                                    class="menu-list"
                                    :class="{ active: showTopStations }"
                                    @click="toggleTopStations()"
                                >
                                    <i class="fa fa-trophy"></i
                                    ><span> Top Stations </span>
                                </div>
                            </div>
                        </div>

                        <div class="h-line"></div>

                        <!--Search History-->
                        <div
                            id="module-right-side-list-history"
                            class="module-right side-list history"
                        >
                            <h2>
                                <i class="fa fa-history" aria-hidden="true"></i
                                >Recent searches
                            </h2>
                            <div
                                id="history-list"
                                class="list"
                                v-for="recent in recentSearches"
                                @click="doRecentSearch(recent)"
                            >
                                {{ recent }}
                            </div>
                        </div>
                        <!--end of search history-->
                        <div class="h-line"></div>
                        <div class="h-line"></div>
                        <div id="logo-footer" class="logoFooter">
                            <span id="brandDetails"
                                ><i
                                    class="fa fa-copyright"
                                    aria-hidden="true"
                                ></i>
                                {{ branding.Trademark }}</span
                            >
                            <div class="shoutCast">
                                <p>Powered by:</p>
                                <img src="/assets/images/shoutcast.png" />
                            </div>
                            <ul>
                                <li>
                                    <a
                                        id="tos-link"
                                        :href="
                                            `https://${branding.RootDomain}/terms-of-service/`
                                        "
                                        >Terms of Service
                                    </a>
                                    |
                                </li>
                                <li>
                                    <a
                                        id="privacy-link"
                                        :href="
                                            `https://${branding.RootDomain}/privacy-policy/`
                                        "
                                        >Privacy</a
                                    >
                                    |
                                </li>
                                <li>
                                    <a
                                        id="contact-us_-ink"
                                        :href="
                                            `https://${branding.RootDomain}/contact-us/`
                                        "
                                        >Contact Us</a
                                    >
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <!--End of Right col-->
            </div>
        </div>
        <!--End of resultes-->
        <!--footer-->
        <footer>
            <div id="serp-footer" class="serp-footer">
                <div
                    class="playRandom"
                    id="playRandomBtn"
                    @click="playRandomStation()"
                >
                    <i class="fa fa-random"></i
                    ><span> Play Random Station </span>
                </div>
                <!--player-->
                <div id="audio-player" class="audioPlayer">
                    <audioplayer
                        :audioSource="audioStream"
                        :stationTitle="currentStationTitle"
                        :stationGenre="currentStationGenre"
                        :stationID="currentStationID"
                        :currentGUID="this.guid"
                        id="audio_player"
                        ref="audioPlayer"
                        @interface="currentStationID = $event"
                    ></audioplayer>
                </div>
                <!-- End of player-->
            </div>
        </footer>
        <!--end of footer-->
    </div>
    <!-- End of main wrapper -->
</template>

<script>
import bp from "babel-polyfill";
import queryString from "query-string";
import "whatwg-fetch";
import Humanize from "humanize-plus";
import { getSetting, setSetting, getGuid } from "../storage";
import {
    WEB_SEARCH_URL,
    openUrl,
    SEARCH_API_URL,
    ADVANCED_SEARCH_API_URL,
    TOP_STATIONS_URL,
    RANDOM_STATIONS_URL,
    PLAY_API_URL,
    PRIMARY_GENRE_LIST_URL,
    SECONDARY_GENRE_LIST_URL,
    GENRE_INFO_URL,
} from "../urls";
import FirstRun from "../components/FirstRun.vue";
import AutoCompleteInput from "../components/AutoCompleteInput.vue";
import IntroBox from "../components/IntroBox.vue";
import {
    getBranding,
    PRODUCT_SEARCH_PROVIDERS,
    altSearchProvidersUrls,
} from "../branding";
import { documentReady } from "../common";
import TR_AudioPlayerVue from "../components/TR_AudioPlayer.vue";

function validatePop(name) {
    switch (name) {
        case "intro":
        case "i":
            return "intro";
        case "t":
        case "tutorial":
            return "tutorial";
        case "it":
            return "intro-tutorial";
    }

    return false;
}

export default {
    name: "serp",
    components: {
        "first-run": FirstRun,
        "auto-complete-input": AutoCompleteInput,
        "intro-box": IntroBox,
        audioplayer: TR_AudioPlayerVue,
    },
    data() {
        const qs = queryString.parse(window.location.search);
        return {
            openUrl,
            recentSearches: [],
            productSearch: true,
            filterTag: null,
            records: [],
            tags: [],
            categoryTags: [],
            searchCompleted: false,
            query: "",
            queryGenre: {
                name: "All",
                count: -1,
                id: -1,
                haschildren: false,
                parentid: -1,
            },
            wQuery: "",
            queryError: false,
            searchTerm: "",
            searchTermGenre: "",
            guid: qs.guid || "",
            productEngine: qs.engine || "",
            whichPop: validatePop(qs.pop) || false,
            branding: getBranding(),
            totalRecords: 0,
            perPageNo: 50,
            isFetching: false,
            isNotTop: true,
            audioStream: "",
            selecting: false,
            currentStationID: "",
            currentStation: "",
            currentStationTitle: "",
            currentStationGenre: "",
            primaryGenres: [],
            primaryGenre: {
                name: "All",
                count: -1,
                id: -1,
                haschildren: false,
                parentid: -1,
            },
            showGenreTiles: true,
            showTopStations: false,
            textArea: document.createElement("textarea"),
        };
    },
    mounted() {
        documentReady.then(async () => {
            this.recentSearches =
                JSON.parse(getSetting("recentSearches")) || [];

            try {
                this.guid = await getGuid();
            } catch (e) {
                // no extension or guid query paraneter
            }

            this.$refs.queryInput.focus();
            await this.getPrimaryGenres();
            this.handleSearchUrl();
            window.addEventListener("popstate", async event => {
                await this.handleOnPopState(event);
            });
        });
    },
    async created() {
        window.onpopstate = () => {
            this.handleSearchUrl();
        };
    },
    methods: {
        toggleTopStations() {
            if (!this.showTopStations) {
                if (this.showGenreTiles) this.showGenreTiles = false;
                this.showTopStations = true;
                this.getTopStations();
            } else if (this.showTopStations) {
                this.showTopStations = false;
                this.records = [];
            }
        },
        toggleGenreTiles() {
            if (!this.showGenreTiles) {
                if (this.showTopStations) this.records = [];
                this.showTopStations = false;
                this.showGenreTiles = true;
            } else if (this.showGenreTiles) this.showGenreTiles = false;
        },
        getGenreImagePath(genreName) {
            let sanatizedPath = genreName.toLowerCase();
            sanatizedPath = sanatizedPath.replace(/[^a-z0-9+]+/gi, "");
            return sanatizedPath;
        },
        getTitleText(result) {
            let output;
            if (result.ct) output = result.ct;
            else if (result.name) {
                let substitute;
                const maxLength = 35;
                if (this.searchTermGenre && !this.searchTerm)
                    substitute = this.searchTermGenre.name;
                else if (this.searchTerm && !this.searchTermGenre)
                    substitute = this.searchTerm;
                else substitute = "N/A";

                const substrings = result.name.split("-");
                if (substrings.length > 1)
                    output = `${substrings[0]} - ${substitute}`;
                else if (result.name.length > maxLength)
                    output = `${result.name.substring(
                        0,
                        maxLength
                    )} - ${substitute}`;
                else output = `${result.name} - ${substitute}`;
            } else output = "N/A";
            return this.decodeText(output);
        },
        getStationText(station) {
            return this.decodeText(station);
        },
        selectGenreTile(genre) {
            if (genre.id == -1) {
                this.getTopStations();
                return;
            }
            this.queryGenre = genre;
            this.doSearch("", false);
        },
        async getTopStations() {
            this.goToTop();
            this.setDefaultQueryGenre();
            this.searchTerm = "";
            this.searchTermGenre = "";

            this.records = [];
            this.searchCompleted = false;

            const qs = queryString.stringify({
                limit: this.perPageNo,
                mt: "audio/mpeg",
            });

            const url = `${TOP_STATIONS_URL}?${qs}`;

            const recordsReq = await fetch(url);
            if (recordsReq.ok) {
                const myRes = await recordsReq.text();
                const xmlResponse = new window.DOMParser().parseFromString(
                    myRes,
                    "text/xml"
                );
                const response = this.xmlToJson(xmlResponse);
                const recordsRes = response.stationlist.station.map(function(
                    item
                ) {
                    return item["@attributes"];
                });
                if (recordsRes) {
                    this.records = recordsRes;
                    this.totalRecords = this.records.length;
                }
                this.searchCompleted = true;
                this.showGenreTiles = false;
            }
        },
        async playRandomStation() {
            if (this.isFetching == false) {
                this.isFetching = true;
                const qs = queryString.stringify({
                    mt: "audio/mpeg",
                    f: "json",
                    limit: "1",
                });

                const url = `${RANDOM_STATIONS_URL}?${qs}`;

                const recordsReq = await fetch(url);
                if (recordsReq.ok) {
                    const recordsRes = await recordsReq.json();
                    const randomStation =
                        recordsRes.response.data.stationlist.station;
                    const thisStation = await this.getStation(
                        randomStation.id,
                        randomStation.name,
                        randomStation.genre
                    );
                    this.isFetching = false;
                    if (
                        typeof thisStation !== "undefined" &&
                        thisStation.Result != true
                    )
                        this.playRandomStation();
                }
            }
        },
        async getPrimaryGenres() {
            const response = await fetch(`${PRIMARY_GENRE_LIST_URL}?f=json`);
            if (response.ok) {
                const result = await response.json();
                const genreList = result.response.data.genrelist;
                const x = {
                    name: "All",
                    count: -1,
                    id: -1,
                    haschildren: false,
                    parentid: -1,
                };
                genreList.genre.unshift(x);
                this.primaryGenres = genreList.genre;
                return genreList.genre;
            }
        },
        goToTop() {
            window.scrollTo(0, 0);
        },
        scrollCheck() {
            window.onscroll = ev => {
                if (window.scrollY == 0) {
                    this.isNotTop = false;
                } else {
                    this.isNotTop = true;
                }
            };
        },
        async startWebSearch() {
            const searchParams = {
                k: this.wQuery,
                guid: this.guid,
            };
            await openUrl(
                `${WEB_SEARCH_URL}?${queryString.stringify(searchParams)}`,
                false
            );
        },
        validateSearch(withFocus) {
            const { query } = this;
            const errs = false;
            this.queryError = errs;
            if (withFocus) {
                if (errs.query) this.$refs.queryInput.focus();
            }
            return errs === false;
        },
        addToBrowserHistory(query, genreID) {
            let qs = {
                ...queryString.parse(window.location.search),
                q: query,
                g: genreID,
            };
            // Add guid if there is one.
            if (this.guid) {
                qs.guid = this.guid;
            }
            qs = queryString.stringify(qs);
            const newUrl = `?${qs}`;
            window.history.pushState(
                { query: this.query },
                `${this.query}`,
                newUrl
            );
        },
        async handleOnPopState(ev) {
            const { state } = ev;
            // console.log('Query/q = ' + state.query + '/' + state.q)
            if (state && state.query) {
                if (this.query !== state.query) {
                    this.query = state.query;
                    await this.doSearch(this.query, false);
                }
            }
        },
        async doSearch(term, addToHistory = true) {
            // whitespace test
            if (!/\S/.test(term)) term = null;

            this.goToTop();
            this.records = [];
            this.searchTerm = "";
            this.searchTermGenre = "";
            this.showGenreTiles = false;
            this.showTopStations = false;

            // Mututally exclusive search
            if (term) this.queryGenre = "";
            if (this.queryGenre) term = "";

            if (term || this.notAllGenre)
                this.addToBrowserHistory(term, this.queryGenre.id);
            if (addToHistory && term) {
                this.recentSearches = this.recentSearches.filter(
                    x => x !== this.query
                );
                if (this.recentSearches.unshift(this.query) > 10) {
                    this.recentSearches.pop();
                }
                setSetting(
                    "recentSearches",
                    JSON.stringify(this.recentSearches)
                );
            }

            this.filterTag = null;
            this.searchCompleted = false;

            let qs = queryString.stringify({
                ct: term,
                limit: this.perPageNo,
                f: "json",
                mt: "audio/mpeg",
            });
            // remove && !this.query if you want query filtering
            if (this.notAllGenre && !this.query) {
                qs += `&genre=${this.queryGenre.name}`;
            }

            let url = `${SEARCH_API_URL}?${qs}`;
            if (!term && this.notAllGenre)
                url = `${ADVANCED_SEARCH_API_URL}?limit=${this.perPageNo}&genre_id=${this.queryGenre.id}&f=json&mt=audio/mpeg`;
            else if (!term) {
                this.getTopStations();
                return;
            }

            const recordsReq = await fetch(url);
            if (recordsReq.ok) {
                if (term) {
                    this.searchTerm = term;
                    this.showGenreTiles = false;
                } else this.searchTerm = "";
                if (this.queryGenre) {
                    this.searchTermGenre = this.queryGenre;
                    if (this.notAllGenre) this.showGenreTiles = false;
                } else this.searchTermGenre = "";
                const recordsRes = await recordsReq.json();
                if (
                    recordsRes &&
                    recordsRes.response.data.stationlist.station
                ) {
                    this.records = recordsRes.response.data.stationlist.station;
                    this.totalRecords = this.records.length;
                }
                this.searchCompleted = true;
                if (!this.hasSearchResults) this.showGenreTiles = true;
            }
        },
        async getStation(id, name, genre) {
            if (id != this.currentStationID) {
                this.currentStationID = "";
                const stationReq = await fetch(`${PLAY_API_URL}?id=${id}`);
                if (stationReq.ok) {
                    const thisStation = await stationReq.json();

                    if (thisStation.Result == true) {
                        this.currentStationID = id;
                        this.currentStationTitle = name;
                        this.currentStationGenre = genre;
                        this.audioStream = thisStation.URL;
                    }

                    return thisStation;
                }
            } else if (id == this.currentStationID && this.audioStream) {
                this.$refs.audioPlayer.setPlay();
            }
        },
        xmlToJson(xml) {
            let obj = {};

            if (xml.nodeType == 1) {
                if (xml.attributes.length > 0) {
                    obj["@attributes"] = {};
                    for (let j = 0; j < xml.attributes.length; j++) {
                        const attribute = xml.attributes.item(j);
                        obj["@attributes"][attribute.nodeName] =
                            attribute.nodeValue;
                    }
                }
            } else if (xml.nodeType == 3) {
                obj = xml.nodeValue;
            }

            if (
                xml.hasChildNodes() &&
                xml.childNodes.length === 1 &&
                xml.childNodes[0].nodeType === 3
            ) {
                obj = xml.childNodes[0].nodeValue;
            } else if (xml.hasChildNodes()) {
                for (let i = 0; i < xml.childNodes.length; i++) {
                    const item = xml.childNodes.item(i);
                    const { nodeName } = item;
                    if (typeof obj[nodeName] === "undefined") {
                        obj[nodeName] = this.xmlToJson(item);
                    } else {
                        if (typeof obj[nodeName].push === "undefined") {
                            const old = obj[nodeName];
                            obj[nodeName] = [];
                            obj[nodeName].push(old);
                        }
                        obj[nodeName].push(this.xmlToJson(item));
                    }
                }
            }
            return obj;
        },
        decodeText(text) {
            this.textArea.innerHTML = text;
            const output = this.textArea.value;
            return output;
        },
        setDefaultQueryGenre() {
            this.queryGenre = {
                name: "All",
                count: -1,
                id: -1,
                haschildren: false,
                parentid: -1,
            };
        },
        filterTagResults(ev, tag) {
            ev.preventDefault();
            this.filterTag = tag;
        },
        resetFilterTag() {
            this.filterTag = null;
        },
        toWebSearch() {
            this.productSearch = false;
            this.$nextTick(() => this.$refs.webSearch.focus());
        },
        toProductSearch() {
            this.productSearch = true;
            this.$nextTick(() => {
                this.$refs.queryInput.focus();
            });
        },
        async handleSearchUrl() {
            const qs = queryString.parse(window.location.search);
            let q;
            let g;
            if (typeof qs.q !== "undefined") q = qs.q;
            if (typeof qs.g !== "undefined") g = qs.g;
            this.query = q || "";
            if (g && g > -1) {
                let x;
                for (x in this.primaryGenres) {
                    if (g == this.primaryGenres[x].id) {
                        this.queryGenre = this.primaryGenres[x];
                        break;
                    }
                }
            } else this.setDefaultQueryGenre();
            if (this.query != "" || this.notAllGenre) {
                await this.doSearch(this.query, false);
            }
        },
        onNextPop(tag) {
            this.whichPop = validatePop(tag) || false;
        },
        onCloseHelp() {
            switch (this.whichPop) {
                case "intro-tutorial":
                    this.whichPop = validatePop("i");
                    return;
            }
            this.whichPop = false;
        },
        async doRecentSearch(term) {
            this.query = term;
            await this.doSearch(this.query);
        },
        async onSugSelected() {
            if (!this.validateSearch(true)) return;
            await this.doSearch(this.query);
        },
        microdataFields(data) {
            Object.keys(data).forEach(
                key => data[key] === "" && delete data[key]
            );
            return data;
        },
    },
    computed: {
        hasSearchResults() {
            return this.numResults > 0;
        },
        numResults() {
            return this.records.length;
        },
        altSearchUrls() {
            return altSearchProvidersUrls(this.query);
        },
        notAllGenre() {
            return this.queryGenre.id > -1;
        },
    },
};
</script>

<style>
@import "./css/serp.css";
</style>
