import { getBranding } from "./branding";
import { sendMessageToExt } from "./storage";
import { isFirefox } from "./common";

const branding = getBranding();

const TOP_DOMAIN =
    (typeof branding !== "undefined" && branding.RootDomain) ||
    (function() {
        const parts = window.location.hostname.split(/\./);
        const domain =
            parts.length == 1
                ? window.location.hostname
                : parts.slice(-2).join(".");
        return (
            domain + (window.location.port ? `:${window.location.port}` : "")
        ).toLowerCase();
    })();

const CURRENT_HOST = `${new URL(window.location).origin}`;

const WEB_SEARCH_URL = `https://services.${TOP_DOMAIN}/crx/search.php`;
const API_ROUTE = `/api`;
const SEARCH_API_ROUTE = `${API_ROUTE}/search`;
const GET_API_ROUTE = `${API_ROUTE}/get`;
const SEARCH_API_URL = `${SEARCH_API_ROUTE}/station/nowplaying/`;
const ADVANCED_SEARCH_API_URL = `${SEARCH_API_ROUTE}/station/advancedsearch/`;
const TOP_STATIONS_URL = `${SEARCH_API_ROUTE}/legacy/Top500/`;
const RANDOM_STATIONS_URL = `${SEARCH_API_ROUTE}/station/randomstations/`;
const PLAY_API_URL = `${API_ROUTE}/play/`;
const PRIMARY_GENRE_LIST_URL = `${GET_API_ROUTE}/genre/primary/`;
const SECONDARY_GENRE_LIST_URL = `${GET_API_ROUTE}/genre/secondary/`;
const GENRE_INFO_URL = `${GET_API_ROUTE}/genre/secondary/`;
const SONG_INFO_API_URL = `${API_ROUTE}/info/`;

function openUrl(toOpen, newTab = true) {
    if (!newTab) {
        return window.open(
            toOpen,
            window.self !== window.top ? "_parent" : "_self"
        );
    }
    if (isFirefox) {
        return sendMessageToExt({ task: "openUrl", url: toOpen });
    }
    window.open(toOpen, "_blank");
}

export {
    WEB_SEARCH_URL,
    API_ROUTE,
    SEARCH_API_URL,
    ADVANCED_SEARCH_API_URL,
    TOP_STATIONS_URL,
    RANDOM_STATIONS_URL,
    PLAY_API_URL,
    PRIMARY_GENRE_LIST_URL,
    SECONDARY_GENRE_LIST_URL,
    GENRE_INFO_URL,
    SONG_INFO_API_URL,
    openUrl,
    TOP_DOMAIN,
    CURRENT_HOST,
};
